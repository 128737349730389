import axiosInstance from "./axios";

export const publisherDetailsFetcher = async () =>
  axiosInstance
    .get("/publishers/publisher-handler")
    .then((response) => response.data);

export const fetchBillRequest = async () =>
  axiosInstance.get("/bills/user-bills").then((response) => response.data);

export const updatePublisherRequest = async (requestData) =>
  axiosInstance
    .put("/publishers/publisher-handler", requestData)
    .then((response) => response.data);

export const fetchGeneralPayoutRequest = async () =>
  axiosInstance
    .get("/publishers/fetch-general-payout-percentage")
    .then((response) => response.data);
