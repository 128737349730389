import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// paths
import { PATH_DASHBOARD, PATH_AUTH } from "./paths";
// components
import LoadingScreen from "../components/LoadingScreen";
import LayoutGenerator from "src/layouts";
import DashboardLayout from "src/layouts/dashboard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Redirect from root to /auth/signin
    {
      path: "/",
      element: <Navigate to={PATH_DASHBOARD.overview.home} replace />,
    },
    // Overview
    {
      path: "/",
      element: <LayoutGenerator />,
      children: [
        { path: PATH_DASHBOARD.overview.home, element: <HomePage /> },
        { path: PATH_DASHBOARD.overview.apps, element: <AppsPage /> },
        { path: PATH_DASHBOARD.overview.campaigns, element: <CampaignsPage /> },
        {
          path: PATH_DASHBOARD.overview.reports.performance,
          element: <PerformanceReportPage />,
        },
        {
          path: PATH_DASHBOARD.overview.reports.reversal,
          element: <ReversalReportPage />,
        },
        { path: PATH_DASHBOARD.overview.billing, element: <BillingPage /> },
        { path: PATH_DASHBOARD.overview.profile, element: <ProfilePage /> },
      ],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: PATH_AUTH.signIn, element: <SignInPage /> },
        { path: PATH_AUTH.signUp, element: <SignupPage /> },
        { path: PATH_AUTH.activateSuccess, element: <ActivateSuccessPage /> },
        { path: PATH_AUTH.activateFailure, element: <ActivateFailurePage /> },
        { path: PATH_AUTH.forgetPassword, element: <ForgetPasswordPage /> },
        { path: PATH_AUTH.resetPassword, element: <ResetPasswordPage /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// -- Home
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
// -- Apps
const AppsPage = Loadable(lazy(() => import("../pages/AppsPage")));
// -- Campaigns
const CampaignsPage = Loadable(lazy(() => import("../pages/CampaignsPage")));
// Reports
// -- Performance Report Page
const PerformanceReportPage = Loadable(
  lazy(() => import("../pages/reportsPage/PerformanceReportPage"))
);
// -- Reversal Report Page
const ReversalReportPage = Loadable(
  lazy(() => import("../pages/reportsPage/ReversalReportPage"))
);
// Billing
const BillingPage = Loadable(lazy(() => import("../pages/BillingPage")));
// Profile
const ProfilePage = Loadable(lazy(() => import("../pages/ProfilePage")));
// auth
// -- SignIn
const SignInPage = Loadable(
  lazy(() => import("../pages/authPages/SignInPage"))
);
// -- Signup
const SignupPage = Loadable(
  lazy(() => import("../pages/authPages/SignUpPage"))
);
// -- Activate Success
const ActivateSuccessPage = Loadable(
  lazy(() => import("../pages/authPages/ActivationSuccessPage"))
);
// -- Activate Failure
const ActivateFailurePage = Loadable(
  lazy(() => import("../pages/authPages/ActivationFailurePage"))
);
// -- Forget Password
const ForgetPasswordPage = Loadable(
  lazy(() => import("../pages/authPages/ForgetPasswordPage"))
);
// -- Reset Password
const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/authPages/ResetPasswordPage"))
);

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
