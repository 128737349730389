import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
// __apis__
import { userDetailsFetcher } from "src/__apis__/accounts";
import { publisherDetailsFetcher } from "src/__apis__/publisher";
// atoms
import userAtom from "src/recoil/atoms/userAtom";
import alertAtom from "src/recoil/atoms/alertAtom";
// Paths
import { PATH_AUTH, PATH_DASHBOARD } from "src/routes/paths";
//
import DashboardLayout from "./dashboard";
import LogoOnlyLayout from "./LogoOnlyLayout";
import LoadingScreen from "src/components/LoadingScreen";

// --------------------------------------------------------------------

function LayoutGenerator(children) {
  const [authenticated, setAuthenticated] = useState(null);
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("access_token");

  const [user, setUser] = useRecoilState(userAtom);
  const triggerAlert = useSetRecoilState(alertAtom);

  const [loadingUserData, setIsLoadingUserData] = useState(false);

  useEffect(() => {
    if (Boolean(accessToken)) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);

      navigate(PATH_AUTH.login);
    }
  }, [accessToken, navigate]);

  const fetchUserDetails = useCallback(async () => {
    setIsLoadingUserData(true);
    await userDetailsFetcher()
      .then(async (response) => {
        console.log("user data", response);
        await fetchPublisherDetails(response);
      })
      .catch((error) => {
        console.log("Error fetching user details", error.response);
      });
    setIsLoadingUserData(false);
  }, []);

  const fetchPublisherDetails = useCallback(
    async (originalResponse) => {
      await publisherDetailsFetcher()
        .then((response) => {
          setUser({...originalResponse, ...response});
        })
        .catch((error) => {
          console.log("Failed fetching publisher details", error);
          triggerAlert({
            triggered: true,
            type: "error",
            message: "Failed loading your account details.",
          });
        });
    },
    []
  );

  useEffect(() => {
    if (authenticated === true) {
      fetchUserDetails();
    }
  }, [authenticated]);

  if (loadingUserData) {
    <>Loading User Data</>;
  }

  return (
    <>
      {authenticated ? (
        <DashboardLayout>{children}</DashboardLayout>
      ) : authenticated === null ? (
        <LoadingScreen />
      ) : (
        <LogoOnlyLayout>{children}</LogoOnlyLayout>
      )}
    </>
  );
}

export default LayoutGenerator;
