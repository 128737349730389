import { useRecoilValue } from "recoil";
// atoms
// utils
import createAvatar from "src/utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ alt, src, ...other }) {
  return (
    <Avatar
      src={src}
      alt={alt}
      color={createAvatar(alt).color || "default"}
      {...other}
    >
      {createAvatar(alt)}
    </Avatar>
  );
}
