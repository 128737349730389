// components
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "../../../components/Iconify";
import HomeIcon from "@mui/icons-material/Home";

// ------------------------------------------------------------------------------------------------

const navConfig = [
  {
    subheader: "Overview",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.overview.home,
        icon: <Iconify icon="iconoir:home" />,
      },
      {
        title: "Apps",
        path: PATH_DASHBOARD.overview.apps,
        icon: <Iconify icon="fluent:apps-add-in-16-regular" />,
      },
      {
        title: "Campaigns",
        path: PATH_DASHBOARD.overview.campaigns,
        icon: <Iconify icon="mingcute:performance-fill" />,
      },
      {
        title: "Reports",
        icon: <Iconify icon="mdi:chart-box" />,
        children: [
          {
            title: "Performance",
            path: PATH_DASHBOARD.overview.reports.performance,
          },
          {
            title: "Reversal",
            path: PATH_DASHBOARD.overview.reports.reversal,
          },
        ],
      },
      {
        title: "Billing",
        path: PATH_DASHBOARD.overview.billing,
        icon: <Iconify icon="solar:dollar-broken" />,
      },
      {
        title: "Profile",
        path: PATH_DASHBOARD.overview.profile,
        icon: <Iconify icon="ci:user-01" />,
      },
    ],
  },
];

export default navConfig;
