// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, "/signin"),
  signUp: path(ROOTS_AUTH, "/signup"),
  activateSuccess: path(ROOTS_AUTH, "/activate/success"),
  activateFailure: path(ROOTS_AUTH, "/activate/failure"),
  forgetPassword: path(ROOTS_AUTH, "/forget-password"),
  resetPassword: path(ROOTS_AUTH, "/reset/:uidb64/:token"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: {
    root: path(ROOTS_DASHBOARD, "/overview"),
    home: path(ROOTS_DASHBOARD, "/overview/home"),
    apps: path(ROOTS_DASHBOARD, "/overview/apps"),
    campaigns: path(ROOTS_DASHBOARD, "/overview/campaigns"),
    reports: {
      root: path(ROOTS_DASHBOARD, "/overview/reports"),
      performance: path(ROOTS_DASHBOARD, "/overview/reports/performance"),
      reversal: path(ROOTS_DASHBOARD, "/overview/reports/reversal"),
    },
    campaigns: path(ROOTS_DASHBOARD, "/overview/campaigns"),
    billing: path(ROOTS_DASHBOARD, "/overview/billing"),
    profile: path(ROOTS_DASHBOARD, "/overview/profile"),
  },
};

export const PATH_DOCS = "https://www.basedontech.com";
